<template>
  <div>
    <v-card-text :style="` width: 100%; height: ${height}px; overflow: auto`" class="pa-0" ref="chat_block">
      <Emulator v-if="loaded" ref="lesson_chat" :chat="chat" @next="onNext($event)" @restart="onRestart($event)" @goto="onGoto($event)" />
      <div class="chat py-3" v-if="loaded && false">
        <component
          :ref="`message-${item.id}`"
          v-for="item in chat"
          :key="item.id"
          :is="`education-message-${types[item.type]}`"
          :result="result.messages.find((r) => r.message_id == item.id)"
          :data="item"
          @next="onNext(item)"
          @restart="onRestart(item, $event)"
          @goto="onGoto(item, $event)"
        />
      </div>
    </v-card-text>
  </div>
</template>

<script>
import { getAccess, popupMenu, genModel } from "@/components/mixings";
export default {
  mixins: [getAccess, popupMenu, genModel],
  components: {
    Emulator: () => import("./lesson"),
  },
  props: {
    height: { type: Number, default: 300 },
    data: Object,
  },
  data() {
    return {
      loaded: false,
      messages: [],
      idEdit: 0,
      m: this.$store.getters["config/get"].models.education.message,
      chat: [],
      types: { 1: "text", 2: "media", 3: "question", 4: "testing" },
      result: {},
    };
  },
  created() {
    this.initData();
  },
  watch: {},
  computed: {
    api() {
      return this.m.api;
    },
  },
  methods: {
    getMessage(id) {
      const m = this.messages.find((m) => m.id == id);
      console.log("add chat message ", id, m, this.messages);
      if (!m) return;
      this.chat.push(m);
      this.scrollTo(id);
    },
    async restart() {
      this.loaded = false;
      // if (this.result.id) await this.$axios.post("/mechti/education/result", { id: this.result?.id, status: 2 });
      this.initData();
    },
    onNext({ item, message, next_id }) {
      this.result["messages"].push(message);
      this.getMessage(next_id);
    },
    onGoto({ item, message, next_id }) {
      console.log("on goto ", { item, message, next_id });
      this.result["messages"].push(message);
      this.getMessage(next_id);
    },
    async onRestart({ mes }) {
      //      this.result["messages"].push(mes);
      this.restart();
    },
    scrollTo(id) {
      this.$nextTick(() => {
        if (!this.$refs.lesson_chat || !this.$refs.lesson_chat.$refs[`message-${id}`]) return;

        const chatBlock = this.$refs.chat_block;
        const newMessageElement = this.$refs.lesson_chat.$refs[`message-${id}`][0];
        if (chatBlock && newMessageElement) {
          const chatBlockHeight = chatBlock.clientHeight;
          const newMessagePosition = newMessageElement.$el.offsetTop;
          const offset = chatBlockHeight;
          chatBlock.scrollTop = newMessagePosition;
        }
      });
    },
    async initData() {
      (this.result = { messages: [] }), (this.chat = []), (this.messages = []);
      await this.fitchData();
      this.chat.push(this.messages.find((m) => (m.id = this.data.education_message_id)));
    },
    async fitchData() {
      this.loaded = !true;
      try {
        let params = {
          filters: { education_chain_id: this.data.id },
        };
        let d = await this.$axios.get(this.api, { params });
        this.messages = [...d.data.data];
        let res = await this.$axios.get("/mechti/education/result", { params });
        //this.result = null; //(res.data.data?.[0] || {})?.data;
        this.loaded = true;
      } catch (error) {
        this.$root.$emit("show-info", { text: "Ошибка загрузки данных" });
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/education.scss";
</style>
